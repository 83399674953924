import React from 'react';
import { ProductsGalleryMobile } from '@mshops-components-library/products-gallery';

import Wrapper from '../Carousel/wrapper';
import withTracks from '../../adapters/withTracks';
import { useRenderContext } from '../../pages/home/context';
import withPolycardGallery from './withPolycardGallery';

const ProductsGalleryWrapper = Wrapper(withTracks(ProductsGalleryMobile));

const EnhancedProductsGallery = (props) => {
  const { device, isEshops, theme, lowEnd } = useRenderContext();

  return (
    <ProductsGalleryWrapper
      layoutTheme={theme}
      deviceType={device}
      lowEnd={lowEnd}
      isEshops={isEshops}
      {...props}
      componentType="collection_grid"
    />
  );
};

export default withPolycardGallery(EnhancedProductsGallery);
