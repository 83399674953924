const { loadable } = require('nordic/lazy');

const UnknownComponent = require('../../../appearance/UnknownComponent').default;

/**
 * NORMAL LIST OF COMPONENTS
 */
const Document = require('../../../appearance/Document').default;
const Layout = require('../../../appearance/Layout').default;

const ShoppingInfoDesktop = loadable(() => import('../../../appearance/ShoppingInfo/desktop'));
const ShoppingInfoMobile = loadable(() => import('../../../appearance/ShoppingInfo/mobile'));
const Row = loadable(() => import('../../../appearance/Row'));
const Slider = loadable(() => import('../../../appearance/Slider'));
const SectionText = loadable(() => import('../../../appearance/SectionText'));
const DataPrivacyInfo = loadable(() => import('../../../appearance/DataPrivacyInfo'));
const ContentList = loadable(() => import('../../../appearance/ContentList'));
const Video = loadable(() => import('../../../appearance/VideoAndText'));
const CategoryCarousel = loadable(() => import('../../../appearance/CategoryCarousel'));
const ContactForm = loadable(() => import('../../../appearance/ContactForm'));
const ContactInfo = loadable(() => import('../../../appearance/ContactInfo'));
const AdhesionContractInfo = loadable(() => import('../../../appearance/AdhesionContractInfo'));
const BankConditionsInfo = loadable(() => import('../../../appearance/BankConditionsInfo'));
const CancelBuyInfo = loadable(() => import('../../../appearance/CancelBuyInfo'));
const PaymentMethodsDesktop = loadable(() => import('../../../appearance/PaymentMethods/desktop'));
const PaymentMethodsMobile = loadable(() => import('../../../appearance/PaymentMethods/mobile'));
const SecondaryBanner = loadable(() => import('../../../appearance/SecondaryBanner'));
const BuyerInfo = loadable(() => import('../../../appearance/BuyerInfo'));
const BadgeCarousel = loadable(() => import('../../../appearance/BadgeCarousel'));
const ProductsCarouselV2 = loadable(() => import('../../../appearance/ProductsCarouselV2'));
const TabWithItems = loadable(() => import('../../../appearance/TabWithItems'));
const MshopItem = loadable(() => import('../../../appearance/Item'));
const NewsLetterModal = loadable(() => import('../../../appearance/NewsLetterModal'));
const NotFoundPage = loadable(() => import('../../../appearance/NotFoundCCP'));
const RichText = loadable(() => import('../../../appearance/RichText'));
const HowBuyInfo = loadable(() => import('../../../appearance/HowBuyInfo'));
const LegalNoticesInfo = loadable(() => import('../../../appearance/LegalNoticesInfo'));
const ProtectedPurchaseInfo = loadable(() => import('../../../appearance/ProtectedPurchaseInfo'));
const DebugInfo = loadable(() => import('../../../appearance/DebugInfo'));
const CategoryGallery = loadable(() => import('../../../appearance/CategoryGallery'));
const CategoryLabelImage = loadable(() => import('../../../appearance/CategoryLabelImage'));

const Grid = require('../../../appearance/Grid').default;

const ProductsGalleryV2Mobile = require('../../../appearance/ProductsGalleryV2/mobile').default;
const ProductsGalleryV2Desktop = require('../../../appearance/ProductsGalleryV2/desktop').default;

const nonEditableComponentList = {
  defaultComponent: UnknownComponent,
  /**
   * All the available components
   */
  nonEditables: {
    Document,
    Layout,
    Row,
    ContactForm,
    ContactInfo,
    Slider,
    Carousel: BadgeCarousel,
    NewsLetterModal,
    HowBuyInfo,
    CancelBuyInfo,
    BankConditionsInfo,
    DataPrivacyInfo,
    LegalNoticesInfo,
    AdhesionContractInfo,
    CategoryCarouselV2: CategoryCarousel,
    RichText,
    PaymentMethods: {
      mobile: PaymentMethodsMobile,
      desktop: PaymentMethodsDesktop,
    },
    ShoppingInfo: {
      mobile: ShoppingInfoMobile,
      desktop: ShoppingInfoDesktop,
    },
    ContentList,
    BuyerInfo,
    TabbedCarousel: {
      mobile: ProductsCarouselV2,
      desktop: ProductsCarouselV2,
    },
    TabWithItems,
    MshopItem,
    CollectionGrid: {
      displayAs: {
        original: {
          mobile: ProductsGalleryV2Mobile,
          desktop: ProductsGalleryV2Desktop,
        },
        material: {
          mobile: ProductsGalleryV2Mobile,
          desktop: ProductsGalleryV2Desktop,
        },
        default: {
          mobile: ProductsGalleryV2Mobile,
          desktop: ProductsGalleryV2Desktop,
        },
      },
    },
    CategoryGallery,
    CategoryLabelImage,
    SectionText,
    Video,
    DebugInfo,
    Grid,
    BannerSplinter: SecondaryBanner,
    NotFoundPage,
    ProtectedPurchaseInfo,
  },
  editables: {},
};

/**
 * EXPORTS
 */
module.exports = nonEditableComponentList;
